const areanav = [
  {name:"Design", url:"/tag/design"},
  {name:"Drawing", url:"/tag/drawing"},
  {name:"Film + Video", url:"/tag/film-+-video"},
  {name:"Installation", url:"/tag/installation"},
  {name:"Painting", url:"/tag/painting"},
  {name:"Performance", url:"/tag/performance"},
  {name:"Photography", url:"/tag/photography"},
  {name:"Printmaking", url:"/tag/printmaking"},
  {name:"Sculpture", url:"/tag/sculpture"},
  {name:"Sound Art", url:"/tag/sound-art"}
]

export default areanav