<template>

  <cursors-display />

</template>
<script>
 
  import CursorsDisplay from '@/components/CursorsDisplay.vue'
  
  export default {
    name: 'DebugCursorsDisplay',
    components:{
      CursorsDisplay
    },
    props:{},
    setup(props){
   
    }
  }
</script>